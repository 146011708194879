import logo from 'bundle-text:../assets/logo.svg';

console.log("♾️");

let logoElem: HTMLDivElement | null = null;
let cursorElem: HTMLDivElement | null = null;
let h1Elem: HTMLElement | null = null;
let sloganElem: HTMLDivElement | null = null;
let shadowElem: HTMLDivElement | null = null;
let getInTouchElem: HTMLElement | null = null;

function isTouchDevice() {
    return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0));
}

document.addEventListener('DOMContentLoaded', () => {
    const timer = setTimeout(() => {
        logoElem?.setAttribute('aria-checked', 'true');
    }, 5000);

    const page1 = document.getElementById('page1');
    const page2 = document.getElementById('page2');
    logoElem = document.getElementById('logo') as HTMLDivElement;
    cursorElem = document.getElementById('cursor') as HTMLDivElement;

    const NUM_CIRCLES = 8; // Adjust the number of circles
    const circlesContainer = document.getElementById("circles-container")!;
    let circles: HTMLDivElement[] = [];
    // Function to generate a random number within a range
    const random = (min: number, max: number) => Math.random() * (max - min) + min;
    let circlesCreated = false;
    // Create circles and animate them flying in
    function createCircles() {
        if (circlesCreated) return;
        circlesCreated = true;
        for (let i = 0; i < NUM_CIRCLES; i++) {
            const circle = document.createElement("div");
            circle.classList.add("circle");
            // Random final position (on screen)
            const finalX = random(50, window.innerWidth - 50);
            const finalY = random(50, window.innerHeight - 50);

            // Random size
            const size = random(20, 500);
            const opacity = random(0.1, 0.4)
            circle.style.width = `${size}px`;
            circle.style.height = `${size}px`;
            circle.style.left = `${finalX}px`;
            circle.style.top = `${finalY}px`;

            // Initial position (off-screen)
            circle.style.scale = `10`;

            circlesContainer.appendChild(circle);
            circles.push(circle);

            // Animate into position
            setTimeout(() => {
                circle.style.scale = `1`;
                circle.style.opacity = `${opacity}`;
            }, random(100, 500)); // Staggered animation times
        }
    }

    // Mouse move effect
    function onMouseMove(event: MouseEvent) {
        const clientX = event.clientX;
        const clientY = event.clientY;

        const { offsetWidth, offsetHeight } = page2!;
    
        // Calculate center position of the logo
        const centerX = offsetWidth / 2;
        const centerY = offsetHeight / 2;

        // Calculate the difference from center
        const deltaX = (clientX - (page2!.getBoundingClientRect().left + centerX)) / centerX;
        const deltaY = (clientY - (page2!.getBoundingClientRect().top + centerY)) / centerY;
        
        circles.forEach((circle) => {
            const size = parseFloat(circle.style.width); // Get circle size in pixels
            // Normalize effect: smaller circles move more
            const effectStrength = Math.max(1, 1000 / size); // Smaller size = bigger effect
            const moveX = deltaX * effectStrength * 10;
            const moveY = deltaY * effectStrength * 10;

            circle.style.transform = `translate(${moveX * 10}px, ${moveY * 10}px)`;
        });
    }

    // Detect when #page2 is visible
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    createCircles();
                    window.addEventListener("mousemove", onMouseMove);
                }
            });
        },
        { threshold: 0.5 }
    );

    observer.observe(document.getElementById("page2")!);

    if (logoElem && page1) {
        logoElem.innerHTML += logo;
        h1Elem = document.getElementById('title') as HTMLElement;
        sloganElem = document.getElementById('slogan') as HTMLDivElement;
        shadowElem = document.getElementById('shadow') as HTMLDivElement;

        // Get the SVG element
        const svgElem = logoElem.querySelector('svg');
        
        if (!isTouchDevice()) {
            document.addEventListener('mousemove', (event) => {
                const { clientX, clientY } = event;
                if (cursorElem) {
                    cursorElem.style.left = `${clientX}px`;
                    cursorElem.style.top = `${clientY}px`;
                }
            });

            const atags = Array.from(document.getElementsByTagName('a'));
            atags.forEach((a) => {
                a.addEventListener('mouseenter', () => {
                    cursorElem!.style.height = '150px';
                    cursorElem!.style.width = '150px';
                });

                a.addEventListener('mouseleave', () => {
                    cursorElem!.style.height = '20px';
                    cursorElem!.style.width = '20px';
                });
            });
            const paragraphs = Array.from(document.getElementsByTagName('p'));
            paragraphs.forEach((paragraph) => {
                paragraph.addEventListener('mouseenter', () => {
                    cursorElem!.style.height = '30px';
                    cursorElem!.style.width = '30px';
                });

                paragraph.addEventListener('mouseleave', () => {
                    cursorElem!.style.height = '20px';
                    cursorElem!.style.width = '20px';
                });
            })
            if (page2) {
                page2.addEventListener('mousemove', (event) => {
                    const { clientX, clientY } = event;
                    const infoElem = document.getElementById("info");
                    getInTouchElem = document.getElementById("get-in-touch");

                    if (getInTouchElem) {
                        const { offsetWidth, offsetHeight} = getInTouchElem;
                        // Calculate center position of the logo
                        const centerX = offsetWidth / 2;
                        const centerY = offsetHeight / 2;

                        // Calculate the difference from center
                        const deltaX = (clientX - (getInTouchElem!.getBoundingClientRect().left + centerX)) / centerX;
                        const deltaY = (clientY - (getInTouchElem!.getBoundingClientRect().top + centerY)) / centerY;
                        if (Math.abs(deltaY) <= 4) {
                            if (!getInTouchElem.style.transform) {
                                getInTouchElem.style.transition = 'all .3s cubic-bezier(0.175, 0.885, 0.32, 1.8)';
                                getInTouchElem.style.transform = `skewX(${deltaX * -5}deg) translate3d(${deltaX * 3}px, ${deltaY * 8}px, 0) rotateX(${deltaX * 2}deg)`;
                                setTimeout(() => {
                                    getInTouchElem!.style.transition = '';
                                }, 300);
                            } else {
                                getInTouchElem.style.transform = `skewX(${deltaX * -5}deg) translate3d(${deltaX * 3}px, ${deltaY * 8}px, 0) rotateX(${deltaX * -2}deg)`;
                            }

                        } else {
                            if (getInTouchElem.style.transform) {
                                getInTouchElem.style.transition = 'all .3s cubic-bezier(0.175, 0.885, 0.32, 1.8)';
                                getInTouchElem.style.transform = '';
                                setTimeout(() => {
                                    getInTouchElem!.style.transition = '';
                                }, 300);
                            }
                        }
                    }

                    if (infoElem) {
                        const beforeElem = document.getElementById("before");
                        const afterElem = document.getElementById("after");
                        const infoTitle = document.getElementById("infoTitle");
                        const infoText = document.getElementById("infoText");

                        const { offsetWidth, offsetHeight } = infoElem;
                        // Calculate center position of the logo
                        const centerX = offsetWidth / 2;
                        const centerY = offsetHeight / 2;

                        // Calculate the difference from center
                        const deltaX = (clientX - (infoElem!.getBoundingClientRect().left + centerX)) / centerX;
                        const deltaY = (clientY - (infoElem!.getBoundingClientRect().top + centerY)) / centerY;


                        // Apply transform for 3D effect
                        infoElem.style.transform = `translate3d(${deltaX * 8}px, ${deltaY * 8}px, 0) 
                                                    rotateY(${deltaX * 5}deg) 
                                                    rotateX(${deltaY * 5}deg)`;

                        beforeElem!.style.transform = `translate3d(${deltaX * 4}px, ${deltaY * 4}px, 0) 
                                                    rotateY(${deltaX * -1}deg) 
                                                    rotateX(${deltaY * -1}deg)`;
                        
                        afterElem!.style.transform = `translate3d(${deltaX * 6}px, ${deltaY * 6}px, 0) 
                                                    rotateY(${deltaX * 3}deg) 
                                                    rotateX(${deltaY * 3}deg)`;

                        infoTitle!.style.transform = `translate3d(${deltaX * -5}px, ${deltaY * -5}px, 0)`;
                        infoText!.style.transform = `translate3d(${deltaX * -5}px, ${deltaY * -5}px, 0)`;
                    }
                })
            }
            // Move the logo based on mouse position
            page1.addEventListener('mousemove', (event) => {
                const { clientX, clientY } = event;
                
                if (svgElem) {
                    const { offsetWidth, offsetHeight } = logoElem!;
    
                    // Calculate center position of the logo
                    const centerX = offsetWidth / 2;
                    const centerY = offsetHeight / 2;
    
                    // Calculate the difference from center
                    const deltaX = (clientX - (logoElem!.getBoundingClientRect().left + centerX)) / centerX;
                    const deltaY = (clientY - (logoElem!.getBoundingClientRect().top + centerY)) / centerY;
    
                    // Calculate distance from the center point to determine scaling
                    const distanceFromCenter = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // Euclidean distance
                    const scale = Math.max(1 + distanceFromCenter * 0.1, 0.8); // Scale down as approaching center, min scale of 0.8
    
                    // Apply transform for 3D effect
                    svgElem.style.transform = `translate3d(${deltaX * 10}px, ${deltaY * 10}px, 0) 
                                                rotateY(${deltaX * 5}deg) 
                                                rotateX(${deltaY * 5}deg)
                                                scale(${scale})`; // Add scale transformation
    
                    if (shadowElem) {
                        shadowElem.style.transform = `translateX(-50%) scale(${scale})`;
                    }
                    
                    if (logoElem!.getAttribute('aria-checked') == 'true') {
                        if (h1Elem) {
                            h1Elem.style.transform = `translate(${deltaX * 10}px, ${deltaY * 10}px)`;
                        }
    
                        if (sloganElem) {
                            sloganElem.style.transform = `translate(${deltaX * 10}px, ${deltaY * 10}px)`;
                        }
                    }
                }
            });
        } else {
            if (page2) {
                page2.addEventListener('touchmove', (event) => {
                    const clientX = event.targetTouches[0].pageX;
                    const clientY = event.targetTouches[0].pageY;
                    const infoElem = document.getElementById("info");

                    if (infoElem) {
                        const beforeElem = document.getElementById("before");
                        const afterElem = document.getElementById("after");
                        const infoTitle = document.getElementById("infoTitle");
                        const infoText = document.getElementById("infoText");

                        const { offsetWidth, offsetHeight } = infoElem;
                        // Calculate center position of the logo
                        const centerX = offsetWidth / 2;
                        const centerY = offsetHeight / 2;

                        // Calculate the difference from center
                        const deltaX = (clientX - (infoElem!.getBoundingClientRect().left + centerX)) / centerX;
                        const deltaY = (clientY - (infoElem!.getBoundingClientRect().top + centerY)) / centerY;


                        // Apply transform for 3D effect
                        infoElem.style.transform = `translate3d(${deltaX * 8}px, ${deltaY * 8}px, 0) 
                                                    rotateY(${deltaX * 5}deg) 
                                                    rotateX(${deltaY * 5}deg)`;

                        beforeElem!.style.transform = `translate3d(${deltaX * 4}px, ${deltaY * 4}px, 0) 
                                                    rotateY(${deltaX * -1}deg) 
                                                    rotateX(${deltaY * -1}deg)`;
                        
                        afterElem!.style.transform = `translate3d(${deltaX * 6}px, ${deltaY * 6}px, 0) 
                                                    rotateY(${deltaX * 3}deg) 
                                                    rotateX(${deltaY * 3}deg)`;

                        infoTitle!.style.transform = `translate3d(${deltaX * -5}px, ${deltaY * -5}px, 0)`;
                        infoText!.style.transform = `translate3d(${deltaX * -5}px, ${deltaY * -5}px, 0)`;
                    }
                })
            }
            page1.addEventListener('touchmove', (event) => {
                if (event.targetTouches.length == 1) {
                    const clientX = event.targetTouches[0].pageX;
                    const clientY = event.targetTouches[0].pageY;
                    if (svgElem) {
                        const { offsetWidth, offsetHeight } = logoElem!;
        
                        // Calculate center position of the logo
                        const centerX = offsetWidth / 2;
                        const centerY = offsetHeight / 2;
        
                        // Calculate the difference from center
                        const deltaX = (clientX - (logoElem!.getBoundingClientRect().left + centerX)) / centerX;
                        const deltaY = (clientY - (logoElem!.getBoundingClientRect().top + centerY)) / centerY;
        
                        // Calculate distance from the center point to determine scaling
                        const distanceFromCenter = Math.sqrt(deltaX * deltaX + deltaY * deltaY); // Euclidean distance
                        const scale = Math.max(1 + distanceFromCenter * 0.1, 0.8); // Scale down as approaching center, min scale of 0.8
        
                        // Apply transform for 3D effect
                        svgElem.style.transform = `translate3d(${deltaX * 10}px, ${deltaY * 10}px, 0) 
                                                    rotateY(${deltaX * 5}deg) 
                                                    rotateX(${deltaY * 5}deg)
                                                    scale(${scale})`; // Add scale transformation
        
                        if (shadowElem) {
                            shadowElem.style.transform = `translateX(-50%) scale(${scale})`;
                        }
                        
                        if (logoElem!.getAttribute('aria-checked') == 'true') {
                            if (h1Elem) {
                                h1Elem.style.transform = `translate(${deltaX * 10}px, ${deltaY * 10}px)`;
                            }
        
                            if (sloganElem) {
                                sloganElem.style.transform = `translate(${deltaX * 10}px, ${deltaY * 10}px)`;
                            }
                        }
                    }
                }
            });
        }

        if (svgElem) {
            svgElem.addEventListener('click', () => {
                logoElem?.setAttribute('aria-checked', 'true');
                clearTimeout(timer);
            });
        }
    }
});
